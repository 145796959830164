import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useSelector, useDispatch } from "react-redux";
import { GET_ERRORS } from "../actions/types";
import { login } from "../actions/userAction";
import { useHistory } from "react-router";
import { HalfCircleSpinner } from "react-epic-spinners";
import { validator } from "./validator";
import LOGO from "../assets/logo.webp";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="#">
        MYSERV
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const Login = () => {
  const [user, setUser] = useState({ email: "", password: "", error: {} });
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const errors = useSelector((state) => state.errorReducer.errors);
  const [loading, setLoading] = useState(false);
  const [errorInputs, seterrorInputs] = useState({});

  const getUserInfo = (e) => {
    const faildFiels = validator(e.target.value, e.target.name);
    seterrorInputs(() => ({
      ...errorInputs,
      [e.target.name]: Object.values(faildFiels)[0],
    }));
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    setLoading(true);
    const data = user;
    e.preventDefault();
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
    dispatch(login(data, history));
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.token) {
      history.push("/");
    }
  });

  useEffect(() => {}, [errors]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}

        <img
          src={LOGO}
          alt="MYSERV"
          onClick={() => history.push("/")}
          style={{ height: "10vh", cursor: "pointer" }}
        />

        <Typography component="h1" variant="h5">
          Connexion
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            error={errorInputs.email ? true : false}
            helperText={errorInputs.email}
            autoComplete="email"
            onChange={getUserInfo}
            autoFocus
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            error={errorInputs.password ? true : false}
            helperText={errorInputs.password}
            onChange={getUserInfo}
            autoComplete="current-password"
          />
          {errors && errors[0] ? (
            <h6 className="error-text">{errors[0]}</h6>
          ) : null}

          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <HalfCircleSpinner size={80} color="rgb(21,0,167)" />
            </div>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                errorInputs !== undefined && (errorInputs.email || errorInputs.password) ? true : false
              }
            >
              Connecter
            </Button>
          )}
          <Grid container></Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Login;
