import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import slide1 from "../assets/slide1.webp";
import slide2 from "../assets/slide2.webp";
import slide3 from "../assets/slide3.webp";
import slide4 from "../assets/slide4.webp";
import myserv5 from "../assets/myserv5.webp";
import "../styles/home.css";
import "../styles/useBootstrap.scss";
import { useHistory } from "react-router-dom";
import vanilla from "vanilla-tilt";
import { Carousel } from "react-bootstrap";
import partnersList from "../util/partners.json";
const Home = () => {
  const history = useHistory();
  // const [resize, setResize] = React.useState(false);
  // useEffect(() => {
  //   window.addEventListener("resize", () => {
  //     if (window.innerWidth <= 992) setResize(true);
  //     else setResize(false);
  //   });
  // });
  useEffect(() => {
    vanilla.init(document.querySelectorAll(".service-cont"), {
      max: 15,
      speed: 450,
      reverse: true,
    });
  }, []);
  const items = [
    {
      src: slide1,
    },
    {
      src: slide2,
    },
    {
      src: slide3,
    },
    {
      src: slide4,
    },
  ];

  return (
    <>
      <NavBar />
      <div style={{ paddingTop: 70 }}>
        <div>
          <Carousel fade>
            {items &&
              items.map((item, i) => (
                <Carousel.Item key={i}>
                  <img
                    className="d-block w-100"
                    src={item.src}
                    alt={item.src}
                  />
                </Carousel.Item>
              ))}
          </Carousel>
        </div>

        <div className="myserv-welcome row">
          <div
            style={{
              justifyContent: "space-around",
              display: "flex",
              flexDirection: "column",
              padding: "0px",
              alignItems: "center",
            }}
            className="col-lg-12"
          >
            <p
              style={{
                fontSize: "35px",
                color: "#4096e9",
                fontFamily: "Myriad Pro",
                paddingTop: "1rem",
                // marginBottom: "2rem",
                fontWeight: 600,
              }}
            >
              Société Spécialisée En Services d'Ingénierie Informatique
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <button
                className="nousContact"
                onClick={() => history.push("/about")}
              >
                Qui sommes Nous ?
              </button>
            </div>
          </div>
        </div>
        {/* Services Section */}
        <div className="services">
          <h1>Nos services</h1>
          <p>
            Nous fournissons des solutions créatives ! Nous développons vos
            idées en des applications puissantes et robustes.
          </p>
          <div className="row services-list">
            <div className="col-lg-4 col-md-6 fade-in one">
              <div
                className="service-cont"
                onClick={() => history.push("/services/developpementMobile")}
              >
                <img src="/sources/serv1.webp" alt="Développement mobile" />
                <h3>Développement mobile</h3>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 fade-in two">
              <div
                className="service-cont"
                onClick={() => history.push("/services/gestionRS")}
              >
                <img
                  src="/sources/serv2.webp"
                  alt="Gestion des réseaux sociaux"
                />
                <h3>Gestion des réseaux sociaux</h3>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 fade-in three">
              <div
                className="service-cont"
                onClick={() => history.push("/services/gestionProjet")}
              >
                <img src="/sources/serv3.webp" alt="services" />
                <h3>Gestion de projet</h3>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 fade-in four">
              <div
                className="service-cont"
                onClick={() => history.push("/services/sysInfo")}
              >
                <img src="/sources/serv4.webp" alt="services" />
                <h3>Système d’information</h3>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 fade-in five">
              <div
                className="service-cont"
                onClick={() => history.push("/services/reseauSec")}
              >
                <img src="/sources/serv5.webp" alt="services" />
                <h3>Réseau et sécurité</h3>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 fade-in six">
              <div
                className="service-cont"
                onClick={() => history.push("/services/devWeb")}
              >
                <img src="/sources/serv6.webp" alt="services" />
                <h3>Développement web</h3>
              </div>
            </div>
          </div>
        </div>

        {/* Nos Valeurs Section */}
        <div className="valeurs">
          <h1>Nos engagements RSE</h1>
          <div>
            <img src={myserv5} alt="my-valeur" />
            <div>
              MYSERV s'engage à soutenir le développement durable, en
              concentrant son travail sur l'environnement, les clients, les
              personnes, la gouvernance et l'éthique pour :
              <br />
              • Offrir un environnement de travail sain, juste et motivant à nos
              employés
              <br />
              • Engagé pour la protection de la planète, ce qui signifie
              notamment que les conseils et services que nous fournissons sont
              compatibles avec la réduction de l'impact environnemental des
              activités de nos clients
              <br />• Assurer des pratiques transparentes et éthiques avec nos
              clients, partenaires et fournisseurs.
            </div>
          </div>
        </div>

        {/* Nos Partenaires Section */}

        <div className="partenaires">
          <h1>Ils nous font confiance</h1>

          <div className="row">
            {partnersList &&
              partnersList.partners.map((partner, index) => (
                <div className="col-sm-4">
                  <div>
                    <a
                      href={partner.href ? partner.href : null}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={partner.src} alt="partenaire" />
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Home;
