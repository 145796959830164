import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Paper } from "@material-ui/core";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import Button from "@material-ui/core/Button";
import { GET_ERRORS } from "../actions/types";
import { sendEvent } from "../actions/eventAction";

const EventDetails = ({ match }) => {
  const dispatch = useDispatch();
  const [openSendDiv, setOpenSendDiv] = useState(false);
  const data = useSelector((state) => state.eventReducer.events);
  const eventId = match.params.eventId;
  const [eventPost, setEventPost] = useState({
    nom: "",
    email: "",
    sujet: "",
    msg: "",
    cv: "",
  });

  const onChange = (e) => {
    setEventPost({ ...eventPost, [e.target.id]: e.target.value });
  };
  const onChangeCV = (e) => {
    setEventPost({ ...eventPost, [e.target.id]: e.target.files[0] });
  };
  useEffect(() => {
    setEventPost({
      ...eventPost,
      sujet: `Candidature pour l'évènement : ${
        data &&
        data.length !== 0 &&
        data.find((elm) => elm._id === eventId).title
      }`,
    });
  }, [data]);
  const onSubmit = (e) => {
    e.preventDefault();
    // console.log(eventPost);
    let formData = new FormData();
    formData.append("info", JSON.stringify(eventPost));
    formData.append("cv", eventPost.cv);
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
    dispatch(sendEvent(formData));
  };

  return (
    <Paper>
      <NavBar />

      <div
        style={{
          justifyContent: "center",
          flexDirection: "column",
          alignAtems: "center",
          padding: "0 25%",
          // width: "50%",
          marginTop: "2rem",
        }}
      >
        <h2
          style={{
            color: "#000",
            fontSize: "22px",
            fontWeight: "600",
            marginBottom: "20px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Détails évènements
        </h2>
        {/* Information Génerale */}
        <div>
          <h4 style={{ textAlign: "left", fontSize: "18px", color: "#4096e9" }}>
            Information Génerale
          </h4>
          <div className="blockInfo">
            {/* Titre */}
            <div className="itemInfo">
              <label className="itemLabel">Titre:</label>
              <label
                className="itemValue"
                // disabled={true}
                id="email"
                required
                //   onChange={onChange}
                type="text"

                // value={user.email}
              >
                {data &&
                  data.length !== 0 &&
                  data.find((elm) => elm._id === eventId).title}
              </label>
            </div>
            {/* Date */}
            <div className="itemInfo">
              <label className="itemLabel">Date:</label>
              <label
                className="itemValue"
                // disabled={true}
                id="email"
                required
                //   onChange={onChange}
                type="text"

                // value={user.email}
              >
                {data &&
                  data.length !== 0 && data.find((elm) => elm._id === eventId).date&&
                  data.find((elm) => elm._id === eventId).date.slice(0, 10)}
                {" à  "}

                {data &&
                  data.length !== 0 &&data.find((elm) => elm._id === eventId).date&&
                  data.find((elm) => elm._id === eventId).date.slice(11, 16)}
              </label>
            </div>
            {/* Description */}
            <div className="itemInfo">
              <label className="itemLabel">Description:</label>
              <label
                className="itemValue"
                // disabled={true}
                id="email"
                required
                //   onChange={onChange}
                type="text"

                // value={user.email}
              >
                {data &&
                  data.length !== 0 &&
                  data.find((elm) => elm._id === eventId).description}
              </label>
            </div>
            {/* Localisation */}
            <div className="itemInfo">
              <label className="itemLabel">Emplacement:</label>
              <label
                className="itemValue"
                // disabled={true}
                id="email"
                required
                //   onChange={onChange}
                type="text"

                // value={user.email}
              >
                {data &&
                  data.length !== 0 &&
                  data.find((elm) => elm._id === eventId).location}
              </label>
            </div>
          </div>
        </div>
        {/* Plus de détails */}
        <div>
          <h4 style={{ textAlign: "left", fontSize: "18px", color: "#4096e9" }}>
            Plus de détails
          </h4>
          <div className="blockInfo">
            {/* Places Disponible */}
            <div className="itemInfo">
              <label className="itemLabel">Places disponible:</label>
              <label
                className="itemValue"
                // disabled={true}
                id="email"
                required
                //   onChange={onChange}
                type="text"

                // value={user.email}
              >
                {data &&
                  data.length !== 0 &&
                  data.find((elm) => elm._id === eventId).nbr_places}
              </label>
            </div>
            {/* Speakers */}
            <div className="itemInfo">
              <label className="itemLabel">Porte parole:</label>
              <label
                className="itemValue"
                // disabled={true}
                id="email"
                required
                //   onChange={onChange}
                type="text"

                // value={user.email}
              >
                {data &&
                  data.length !== 0 &&
                  data.find((elm) => elm._id === eventId).speakers}
              </label>
            </div>
            {/* Sponsors */}
            <div className="itemInfo">
              <label className="itemLabel">Sponsors:</label>
              <label
                className="itemValue"
                // disabled={true}
                id="email"
                required
                //   onChange={onChange}
                type="text"

                // value={user.email}
              >
                {data &&
                  data.length !== 0 &&
                  data.find((elm) => elm._id === eventId).sponsor}
              </label>
            </div>
            {/* MOT_CLE */}
            <div className="itemInfo">
              <label className="itemLabel">Mot clé:</label>
              <label
                className="itemValue"
                // disabled={true}
                id="email"
                required
                //   onChange={onChange}
                type="text"

                // value={user.email}
              >
                {data &&
                  data.length !== 0 &&
                  data.find((elm) => elm._id === eventId).key_word}
              </label>
            </div>
          </div>
        </div>
        <div>
          {/* Btn */}
          <div
            style={{
              marginBottom: "1.5rem",
              boxSizing: "border-box",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Button
              style={{
                backgroundColor: "#f1782f",
                color: "white",
                fontWeight: 700,
                padding: "5px 10px",
              }}
              onClick={() => setOpenSendDiv(true)}
            >
              Participer
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: openSendDiv ? "block" : "none",
          justifyContent: "center",
          flexDirection: "column",
          alignAtems: "center",
          padding: "0 25%",
          // width: "50%",
          marginTop: "2rem",
        }}
      >
        <h2
          style={{
            color: "#000",
            fontSize: "22px",
            fontWeight: "600",
            marginBottom: "20px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Ouvrir une demande
        </h2>
        <div
          style={{
            color: "#000",
            padding: "0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <form
            style={{
              width: "100%",
              transition: ".5s",
              padding: "3rem!important",
            }}
            onSubmit={onSubmit}
          >
            <label
              style={{
                // paddingLeft: "1.5rem",

                float: "left",
              }}
            >
              Nom*
            </label>
            <input
              type="text"
              id="nom"
              required
              onChange={onChange}
              value={eventPost.nom}
              style={{
                // backgroundColor: "#ffd99c",
                marginBottom: ".5rem!important",
                display: "block",
                width: "100%",
                height: "calc(1.5em + .75rem + 2px)",
                padding: ".375rem .75rem",
                fontSize: "1rem",
                fontWeight: "400",
                lineHeight: "1.5",
                color: "#495057",
                backgroundClip: "padding-box",
                border: "1px solid #4096e9",
                borderRadius: ".25rem",
                transition:
                  "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                marginTop: ".25rem",
                marginBottom: ".5rem",
              }}
            />
            <label
              style={{
                // paddingLeft: "1.5rem",

                float: "left",
              }}
            >
              E-mail*
            </label>
            <input
              type="email"
              id="email"
              required
              value={eventPost.email}
              onChange={onChange}
              style={{
                // backgroundColor: "#ffd99c",
                marginBottom: ".5rem!important",
                display: "block",
                width: "100%",
                height: "calc(1.5em + .75rem + 2px)",
                padding: ".375rem .75rem",
                fontSize: "1rem",
                fontWeight: "400",
                lineHeight: "1.5",
                color: "#495057",
                backgroundClip: "padding-box",
                border: "1px solid #4096e9",
                borderRadius: ".25rem",
                transition:
                  "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                marginTop: ".25rem",
                marginBottom: ".5rem",
              }}
            />
            <label
              style={{
                // paddingLeft: "1.5rem",

                float: "left",
              }}
            >
              Sujet
            </label>
            <input
              type="text"
              id="sujet"
              value={eventPost.sujet}
              required
              disabled
              onChange={onChange}
              style={{
                // backgroundColor: "#ffd99c",
                marginBottom: ".5rem!important",
                display: "block",
                width: "100%",
                height: "calc(1.5em + .75rem + 2px)",
                padding: ".375rem .75rem",
                fontSize: "1rem",
                fontWeight: "400",
                lineHeight: "1.5",
                color: "#495057",
                backgroundClip: "padding-box",
                border: "1px solid #4096e9",
                borderRadius: ".25rem",
                transition:
                  "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                marginTop: ".25rem",
                marginBottom: ".5rem",
              }}
            />
            <div style={{ marginBottom: "1rem" }}>
              <label
                style={{
                  // paddingLeft: "1.5rem",

                  float: "left",
                }}
              >
                Message*
              </label>
              <textarea
                id="msg"
                value={eventPost.msg}
                required
                onChange={onChange}
                rows="5"
                style={{
                  height: "auto",
                  // backgroundColor: "#ffd99c",
                  marginBottom: ".5rem!important",
                  display: "block",
                  width: "100%",
                  height: "150px",
                  padding: ".375rem .75rem",
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.5",
                  color: "#495057",
                  backgroundClip: "padding-box",
                  border: "1px solid #4096e9",
                  borderRadius: ".25rem",
                  transition:
                    "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                  marginTop: ".5rem",
                  marginBottom: ".75rem",
                }}
              ></textarea>
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "start",
                // color: "gray",
                marginBottom: "10px",
                // padingRight: "5px",
              }}
            >
              <p>
              Selectionner ton CV :
              </p>
              <input
                id="cv"
                type="file"
                // required
                onChange={onChangeCV}
                accept=".pdf"
              />
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "start",
                color: "gray",
                marginBottom: "10px",
              }}
            >
              *Champs requis
            </div>
            <Button
              type="submit"
              style={{
                backgroundColor: "#f1782f",
                color: "white",
                fontWeight: 700,
                padding: "5px 10px",
              }}
            >
              Envoyer
            </Button>
          </form>
          <div style={{ display: "none" }}>
            <p>
              Un ou plusieurs champs contiennent une erreur. Veuillez vérifier
              et essayer à nouveau
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </Paper>
  );
};

export default EventDetails;
