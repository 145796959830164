import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Paper } from "@material-ui/core";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import IconButton from "@mui/material/IconButton";
import "../styles/details.css";
import { getOffre } from "../actions/offreAction";
import ApplyingDialog from "./ApplyDialog";
import { EMAIL_SENT, EMAIL_ERROR } from "../actions/types";
import SnackbarContent from "@mui/material/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import "../styles/applyDialog.css";

const OffreDetails = ({ match }) => {
  const dispatch = useDispatch();
  const initAlert = {
    message: "",
    backgroundColor: "",
    open: false,
  };
  const [alert, setAlert] = useState(initAlert);
  const [open, setOpen] = useState(false);
  const data = useSelector((state) => state.offreReducer.offre);
  const success = useSelector((state) => state.sucessReducer.emailMsg);
  const errorr = useSelector((state) => state.errorReducer.emailError);
  const offreId = match.params.offreId;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(initAlert);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    dispatch(getOffre(offreId));
  }, []);

  useEffect(() => {
    let newAlt = {};
    if (success) {
      newAlt = {
        message: success,
        backgroundColor: "#2e7d32",
        open: true,
      };
      handleClose();
    } else if (errorr) {
      newAlt = {
        message: errorr,
        backgroundColor: "#d32f2f",
        open: true,
      };
    }
    setAlert(newAlt);
  }, [success, errorr]);
  useEffect(() => {}, [alert.open]);

  useEffect(() => {
    setAlert(initAlert);
    dispatch({
      type: EMAIL_SENT,
      payload: null,
    });
    dispatch({
      type: EMAIL_ERROR,
      payload: null,
    });
  }, []);

  return (
    <Paper>
      <NavBar />
      <div
        style={{
          justifyContent: "center",
          flexDirection: "column",
          alignAtems: "center",
          padding: "0 25%",
          marginTop: "5%",
        }}
      >
        <h2
          style={{
            color: "#000",
            fontSize: "26px",
            fontWeight: "600",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Détails d'offre
        </h2>

        {alert && alert.open ? (
          <Snackbar
            style={{ top: 120 }}
            open={alert.open}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            message="Note archived"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <SnackbarContent
              style={{
                backgroundColor: alert.backgroundColor,
              }}
              action={action}
              message={<span id="client-snackbar"> {alert.message} </span>}
            />
          </Snackbar>
        ) : null}

        <div>
          <h4 style={{ textAlign: "left", fontSize: "18px", color: "#4096e9" }}>
            Information génerale
          </h4>
          <div className="blockInfo">
            <div className="itemInfo">
              <label className="itemLabel">Titre</label>
              <label className="itemValue" id="email" required type="text">
                {data && data.title}
              </label>
            </div>
            <div className="itemInfo">
              <label className="itemLabel">Type de contrat</label>
              <label className="itemValue" id="email" required type="text">
                {data && data.contratType}
              </label>
            </div>
            <div className="itemInfo">
              <label className="itemLabel">Catégorie</label>
              <label className="itemValue" id="email" required type="text">
                {data && data.category}
              </label>
            </div>
            <div className="itemInfo">
              <label className="itemLabel">Description</label>
              <label className="itemValue" id="email" required type="text">
                <div className="mission">{data && data.description}</div>
              </label>
            </div>
            <div className="itemInfo">
              <label className="itemLabel">Exigences de l'emploi</label>
              <label className="itemValue" id="email" required type="text">
                <div className="mission">{data && data.missions}</div>
              </label>
            </div>
            <div className="itemInfo">
              <label className="itemLabel">Nombre de postes vacants</label>
              <label className="itemValue" id="email" required type="text">
                {data && data.nbPostes}
              </label>
            </div>
          </div>
        </div>
        <div>
          <h4 style={{ textAlign: "left", fontSize: "18px", color: "#4096e9" }}>
            Emplacement
          </h4>
          <div className="blockInfo">
            <div className="itemInfo">
              <label className="itemLabel">Ville</label>
              <label className="itemValue" id="email" required type="text">
                {data && data.location}
              </label>
            </div>
            <div className="itemInfo">
              <label className="itemLabel">Pays</label>
              <label className="itemValue" id="email" required type="text">
                {data && data.country}
              </label>
            </div>
            <div className="itemInfo">
              <label className="itemLabel">Gouvernorat</label>
              <label className="itemValue" id="email" required type="text">
                {data && data.gouvernorat}
              </label>
            </div>
            <div className="itemInfo">
              <label className="itemLabel">Code postal</label>
              <label className="itemValue" id="email" required type="text">
                {data && data.postalCode}
              </label>
            </div>
          </div>
        </div>
        <div>
          <h4 style={{ textAlign: "left", fontSize: "18px", color: "#4096e9" }}>
            Profil demandé
          </h4>
          <div className="blockInfo">
            <div className="itemInfo">
              <label className="itemLabel">Niveau</label>
              <label className="itemValue" id="email" required type="text">
                {data && data.studylevel}
              </label>
            </div>
            <div className="itemInfo">
              <label className="itemLabel">Expérience</label>
              <label className="itemValue" id="email" required type="text">
                {data && data.experience}
              </label>
            </div>
            <div className="itemInfo">
              <label className="itemLabel">Langues</label>
              <label className="itemValue" id="email" required type="text">
                {data && data.language}
              </label>
            </div>
          </div>
        </div>

        {data.rémunérationProposée ? (
          <div>
            <h4
              style={{ textAlign: "left", fontSize: "18px", color: "#4096e9" }}
            >
              Rémunération proposée
            </h4>
            <div className="blockInfo">
              <div className="itemInfo">
                <label
                  className="itemValue None"
                  id="email"
                  required
                  type="text"
                >
                  {data && data.rémunérationProposée}
                </label>
              </div>
            </div>
          </div>
        ) : null}

        <div>
          <h4 style={{ textAlign: "left", fontSize: "18px", color: "#4096e9" }}>
            Date d'expiration
          </h4>
          <div className="blockInfo">
            <div className="itemInfo">
              <label className="itemValue None" id="email" required type="text">
                {data &&
                  data.expirationDate &&
                  data.expirationDate.slice(0, 10)}
                {" à  "}

                {data &&
                  data.expirationDate &&
                  data.expirationDate.slice(11, 16)}
              </label>
            </div>
          </div>
        </div>
        <div
          style={{
            marginBottom: "1.5rem",
            boxSizing: "border-box",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <button
            className="button-dialog"
            style={{ float: "unset" }}
            onClick={() => handleClickOpen(true)}
          >
            Postuler
          </button>
        </div>
      </div>
      {open ? (
        <ApplyingDialog
          open={open}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
          title={data.title}
          sendTo={data.email}
        />
      ) : null}

      <Footer />
    </Paper>
  );
};

export default OffreDetails;
