import { GET_SPEAKERS } from "../actions/types";

const initialState = {
  speakers: [],
};
export default function speakers(state = initialState, action) {
  switch (action.type) {
    case GET_SPEAKERS:
      return {
        ...state,
        speakers: action.payload,
      };
    default:
      return state;
  }
}
