import axios from "axios";
import { GET_ERRORS, GET_OFFRE, GET_OFFRES, GET_SUCCESS, EMAIL_SENT, EMAIL_ERROR } from "../actions/types";

//import setAuthToken from "../token/authtoken";

//--------------Create Offre -------------------------
export const createOffre = (offreData) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .post(`${process.env.REACT_APP_API_URL}/offres/create`, offreData)
    .then((res) => {
      dispatch({
        type: GET_ERRORS,
        payload: { offre: "Offre Created succesfully" },
      });
      dispatch(getOffresList());
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//---------------Get Projects List-------------------

export const getOffresList = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/offres`)
    .then((res) => {
      dispatch({
        type: GET_OFFRES,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      // console.log(err);
    });
};

export const getOffre = (id) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/offres/${id}`)
    .then((res) => {
      dispatch({
        type: GET_OFFRE,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      // console.log(err);
    });
};

//--------------Edit Offre----------------
export const editOffre = (offreData, id) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .patch(`${process.env.REACT_APP_API_URL}/offres/${id}`, offreData)
    .then((res) => {
      dispatch(getOffresList());
      dispatch({
        type: GET_ERRORS,
        payload: { offre: "Offre Updated" },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//---------------Delete Offre--------------------
export const deleteOffre = (id) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .delete(`${process.env.REACT_APP_API_URL}/offres/${id}`)
    .then((res) => {
      dispatch(getOffresList());
      dispatch({
        type: GET_ERRORS,
        payload: { offre: "Offre Deleted" },
      });
      dispatch(getOffresList());
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const sendOffre = (data) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/offres/candidature`, data)
    .then((res) => {
      dispatch({
        type: EMAIL_SENT,
        payload: res.data,
      });
    })
    .catch((err) =>
      dispatch({
        type: EMAIL_ERROR,
        payload: err.response.data,
      })
    );
};
