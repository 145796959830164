import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Paper } from "@material-ui/core";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import RoomIcon from "@material-ui/icons/Room";
import EmailIcon from "@material-ui/icons/Email";
import "../styles/offres.css";
import { getEventsList } from "../actions/index";
const Evennements = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.eventReducer.events);
  useEffect(() => {
    dispatch(getEventsList());
  }, []);
  return (
    <Paper>
      <NavBar />
      <div style={{ paddingTop: 70 }}>
        <div className="title_wrapp">
          <h1 className="title">Nos Évènements</h1>
        </div>

        <Container>
          {data.length !== 0 ? (
            <Row style={{ margin: "5%" }}>
              {data.map((event) => (
                <Col lg={4} md={6} sm={10} key={event._id}>
                  <div
                    style={{
                      // height: "400px",
                      width: "275px",
                      backgroundColor: "#dcdcdc",
                      margin: "30px",
                      padding: "30px",
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }}
                  >
                    <div
                      style={{
                        backgroundImage:
                          "linear-gradient(0.10turn, #ff6ec7, #7067f6 80%)",
                        marginTop: "-50px",
                        padding: "10px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        boxShadow: "0 10px 25px #666",
                      }}
                    >
                      <h2 style={{ fontSize: "24px" }}>{event.title}</h2>
                      <h4 style={{ fontSize: "24px" }}>
                        {event.date && event.date.slice(0, 10)}
                        {"   "}
                        {event.date && event.date.slice(11, 16)}
                      </h4>
                      <Button
                        style={{
                          backgroundColor: "#f0cdc9",
                          marginTop: "15px",
                        }}
                        onClick={() =>
                          history.push(`/EventDetails/${event._id}`)
                        }
                      >
                        En savoir plus
                      </Button>
                    </div>
                    <div
                      style={{
                        bottom: 0,
                        textAlign: "left",
                        marginTop: "10px",
                        lineHeight: "30px",
                      }}
                    >
                      <div style={{ borderBottom: "inset" }}>
                        <RoomIcon />
                        {event.location}
                      </div>
                      <div style={{ borderBottom: "inset" }}>
                        <EmailIcon />
                        {event.email}
                      </div>
                      <div style={{ wordWrap: "break-word" }}>
                        {event.description}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            data && (
              <div className="no-offre">
                <p>
                  <i className="fas fa-exclamation"></i>
                  Pas d'évènements disponibles pour le moment
                </p>
              </div>
            )
          )}
        </Container>
        <Footer />
      </div>
    </Paper>
  );
};

export default Evennements;
