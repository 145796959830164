export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CONGES = "GET_CONGES";
export const GET_EVENTS = "GET_EVENTS";
export const GET_OFFRES = "GET_OFFRES";
export const GET_AUTORISATIONS = "GET_AUTORISATIONS";
export const GET_PROJECTS = "GET_PROJECTS";
export const GET_TASKS = "GET_TASKS";
export const GET_USERS = "GET_USERS";
export const GET_SPONSORS = "GET_SPONSORS";
export const GET_SPEAKERS = "GET_SPEAKERS";
export const GET_ERRORS = "GET_ERRORS";
export const GET_AUTH_USER = "GET_AUTH_USER";
export const GET_OFFRE = "GET_OFFRE";
export const GET_SUCCESS = "GET_SUCCESS";
export const EMAIL_SENT = "EMAIL_SENT";
export const EMAIL_ERROR ="EMAIL_ERROR";