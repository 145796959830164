import { GET_CONGES } from "../actions/types";

const initialState = {
  conges: [],
};
export default function conges(state = initialState, action) {
  switch (action.type) {
    case GET_CONGES:
      return {
        ...state,
        conges: action.payload,
      };
    default:
      return state;
  }
}
