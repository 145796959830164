import React, { useState } from "react";
import { Paper } from "@material-ui/core";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import MailIcon from "@material-ui/icons/Mail";
import "../styles/contact.css";
import axios from "axios";

const Contact = () => {
  const [message, setMessage] = useState({
    nom: "",
    email: "",
    sujet: "",
    msg: "",
  });
  const onChange = (e) => {
    setMessage({ ...message, [e.target.id]: e.target.value });
  };
  const submit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/contact/send`, message)
      .then((res) => {
        console.log("response contact",res);
        setMessage({
          nom: "",
          email: "",
          sujet: "",
          msg: "",
        });
      })
      .catch((err) => console.log(err.message));
  };
  return (
    <Paper>
      <NavBar />
      <div
        style={{
          justifyContent: "center",
          flexDirection: "column",
          alignAtems: "center",
          padding: "0 25%",
          marginTop: "4rem",
          paddingTop: 70,
        }}
      >
        <h2 className="about-ct">Contact</h2>
        <div
          style={{
            color: "#000",
            padding: "0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <form
            style={{
              width: "100%",
              transition: ".5s",
              padding: "3rem!important",
            }}
            onSubmit={submit}
          >
            <label className="labelContact">Nom*</label>
            <input
              type="text"
              id="nom"
              required
              onChange={onChange}
              value={message.nom}
              className="inputForm"
            />
            <label className="labelContact">E-mail*</label>
            <input
              type="email"
              id="email"
              required
              value={message.email}
              onChange={onChange}
              className="inputForm"
            />
            <label className="labelContact">Sujet*</label>
            <input
              type="text"
              id="sujet"
              value={message.sujet}
              required
              onChange={onChange}
              className="inputForm"
            />
            <div style={{ marginBottom: "1rem" }}>
              <label className="labelContact">Message*</label>
              <textarea
                id="msg"
                value={message.msg}
                required
                onChange={onChange}
                rows="5"
                className="textareaForm"
              ></textarea>
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "start",
                color: "gray",
                marginBottom: "10px",
              }}
            >
              *Champs requis
            </div>
            <button type="submit" className="nousContact">
              Envoyer
            </button>
          </form>
          <div style={{ display: "none" }}>
            <p>
              Un ou plusieurs champs contiennent une erreur. Veuillez vérifier
              et essayer à nouveau
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          display: "flex",
          marginLeft: "10%",
          marginTop: "3rem",
          height: "400px",
        }}
        className="contact_map"
      >
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#c2f2f9",
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
            justifyContent: "space-evenly",
            fontSize: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <PhoneIcon />
            <p style={{ paddingLeft: "1rem" }}>+216 28 710 412</p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <RoomIcon />
            <p style={{ paddingLeft: "1rem" }}>
              11, rue Saad Zaghloul M’saken 4070, Tunisie
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <MailIcon />
            <p style={{ paddingLeft: "1rem" }}>contact@myserv-consulting.com</p>
          </div>
        </div>

        <div
          style={{
            width: "50%",
            position: "relative",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
        >
          <iframe
            style={{
              border: 0,
              width: "100%",
              height: "100%",
              borderTopRightRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
            title="myFrame"
            frameBorder="0"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBSZLoDPxvY96AxQbxFDlhXxiTS5JxgeDc&q=MYSERV, Masakin"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <Footer />
    </Paper>
  );
};

export default Contact;
