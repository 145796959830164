import axios from "axios";
import { GET_ERRORS } from "../actions/types";
import { GET_USERS, GET_AUTH_USER } from "../actions/types";

//import setAuthToken from "../token/authtoken";

//--------------Create User -------------------------
export const createUser = (userData) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .post(`${process.env.REACT_APP_API_URL}/users/create`, userData)
    .then((res) => {
      dispatch({
        type: GET_ERRORS,
        payload: { user: "User Created succesfully" },
      });
      dispatch(getUsersList());
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//--------------Login -------------------------
export const login = (userData, history) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .post(`${process.env.REACT_APP_API_URL}/auth`, userData)
    .then((res) => {
      dispatch({
        type: GET_ERRORS,
        payload: { user: "User loged In succesfully" },
      });
      dispatch({
        type: GET_AUTH_USER,
        payload: res.data,
      });
      console.log("auth", res.data);
      const { accessToken } = res.data;
      localStorage.setItem("token", accessToken); //save token in the local storage
      // dispatch(getUsersList());
      history.push("/");
    })
    .catch((err) =>{
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    }
    
    );
};

//---------------Get Projects List-------------------

export const getUsersList = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/users`)
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      // console.log(err);
    });
};

export const getAuthUser = (id) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/users/${id}`)
    .then((res) => {
      dispatch({
        type: GET_AUTH_USER,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      // console.log(err);
    });
};

//--------------Edit User----------------
export const editUser = (userData, id) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .put(`${process.env.REACT_APP_API_URL}/updateUser/${id}`, userData)
    .then((res) => {
      console.log('res', res)
      // dispatch(getUsersList());
      dispatch({
        type: GET_ERRORS,
        payload: { user: "User Updated" },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//---------------Delete User--------------------
export const deleteUser = (id) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .delete(`${process.env.REACT_APP_API_URL}/users/${id}`)
    .then((res) => {
      dispatch(getUsersList());
      dispatch({
        type: GET_ERRORS,
        payload: { user: "User Deleted" },
      });
      dispatch(getUsersList());
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("token");
  // Set current user to empty object {} which will set isAuthenticated to false
  // dispatch(setCurrentUser({}));
};
