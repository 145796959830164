import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import "../styles/service.css";

const Services = ({ match }) => {
  
  useEffect(() => {
    document.getElementById(match.params.sectionId).scrollIntoView()
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <NavBar />
      <div className="service-myserv" id="nosServices">
        <h1>Nos services</h1>
        <div id="developpementMobile">
          <div>
            <img src="/sources/serv1.webp" alt="serv" />
            <h3>Développement mobile</h3>
          </div>
          <div>
            <div>
              Après avoir discuté de vos objectifs, notre équipe de développeurs
              d'applications utilisera la technologie pour répondre à vos
              besoins uniques, vous permettant d'atteindre les objectifs de
              projet à court terme tout en pilotant votre stratégie commerciale
              à long terme. Nous développons trois types d'applications mobiles
              : Android, IOS et applications hybrides.
            </div>
          </div>
        </div>

        <div id="gestionRS">
          <div>
            <div>
              Facebook, Twitter, Instagram, LinkedIn et même Pinterest. Les
              réseaux sociaux envahissent notre quotidien. Notre agent MYSERV
              est spécialisé dans la gestion des réseaux sociaux et est
              responsable de tous les aspects de la gestion de votre communauté
              : stratégie médias sociaux, création de compte, révision et
              création de contenu.
            </div>
          </div>
          <div>
            <img src="/sources/serv2.webp" alt="serv" />
            <h3>Gestion des réseaux sociaux</h3>
          </div>
        </div>

        <div id="gestionProjet" >
          <div>
            <img src="/sources/serv3.webp" alt="serv" />
            <h3>Gestion de projet</h3>
          </div>
          <div>
            <div>
              MYSERV apporte ce haut niveau d'expertise et une riche expérience
              informatique. Elle a également développé une écoute intelligente
              des besoins des clients pour effectuer une analyse rigoureuse de
              l'environnement afin de s'assurer que les résultats répondent aux
              attentes des clients
            </div>
          </div>
        </div>

        <div id="sysInfo" >
          <div>
            <div>
              Vous souhaitez disposer d'un système d'information performant,
              évolutif et optimisé, ou disposer d'un SI performant et adapté à
              votre organisation. MYSERV vous apportera une meilleure
              compréhension de votre SI à travers des suggestions, des analyses
              et des suggestions détaillées.
            </div>
          </div>
          <div>
            <img src="/sources/serv4.webp" alt="serv" />
            <h3>Système d’information</h3>
          </div>
        </div>

        <div id="reseauSec" >
          <div>
            <img src="/sources/serv5.webp" alt="serv" />
            <h3>Réseau et sécurité</h3>
          </div>
          <div>
            <div>
              MYSERV vous accompagne pour répondre à tous les besoins de
              sécurité réseau et informatique. Nous souhaitons maintenir la
              sécurité de votre solution tout au long du cycle de vie :
              formation, évaluation des risques, surveillance de la sécurité,
              gestion des vulnérabilités, gestion des incidents et reprise.
            </div>
          </div>
        </div>

        <div id="devWeb" >
          <div>
            <div>
              MYSERV vous propose des solutions sur mesure de haute qualité pour
              répondre à vos besoins. Fonctions et principales caractéristiques
              liées à votre industrie, design unique, contenu flexible,
              performances ... nous avons pris en compte toutes les
              problématiques.
            </div>
          </div>
          <div>
            <img src="/sources/serv6.webp" alt="serv" />
            <h3>Développement web</h3>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
};

export default Services;
