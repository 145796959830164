import { GET_USERS, GET_AUTH_USER } from "../actions/types";

const initialState = {
  users: [],
  user: {}
};
export default function users(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
      case GET_AUTH_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
