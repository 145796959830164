// import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import clientReducer from "./client";
import congeReducer from "./conge";
import eventReducer from "./event";
import offreReducer from "./offre";
import autorisationReducer from "./autorisation";
import projectReducer from "./project";
import taskReducer from "./task";
import errorReducer from "./errors";
import userReducer from "./user";
import speakerReducer from "./speaker";
import sponsorReducer from "./sponsor";
import sucessReducer from './seccess';

export default combineReducers({
  clientReducer: clientReducer,
  congeReducer: congeReducer,
  eventReducer: eventReducer,
  offreReducer: offreReducer,
  autorisationReducer: autorisationReducer,
  projectReducer: projectReducer,
  taskReducer: taskReducer,
  errorReducer: errorReducer,
  userReducer: userReducer,
  speakerReducer: speakerReducer,
  sponsorReducer: sponsorReducer,
  sucessReducer: sucessReducer
});
