import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import RoomIcon from "@material-ui/icons/Room";
import AlarmIcon from "@mui/icons-material/Alarm";
import WorkIcon from "@mui/icons-material/Work";
import "../styles/offres.css";
import { getOffresList } from "../actions/index";
const Offres = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.offreReducer.offres);

  useEffect(() => {
    dispatch(getOffresList());
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <NavBar />
      <div style={{ paddingTop: 70 }}>
        <h1 className="title">Nos Offres</h1>

        <Container>
          {data.length !== 0 ? (
            <Row style={{ margin: "5%" }}>
              {" "}
              {data.map((offre) => (
                <Col lg={4} md={6} sm={10} key={offre._id}>
                  <div
                    style={{
                      // height: "400px",
                      width: "275px",
                      backgroundColor: "#dcdcdc",
                      margin: "30px",
                      padding: "30px",
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                      borderTopLeftRadius: "10px",
                      borderBottomLeftRadius: "10px",
                    }}
                  >
                    <div
                      style={{
                        backgroundImage:
                          "linear-gradient(0.10turn, #25e1fa, #4096e9 60%)",
                        marginTop: "-50px",
                        padding: "10px",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        boxShadow: "0 10px 25px #666",
                      }}
                    >
                      <h2 style={{ fontSize: "24px" }}>{offre.title}</h2>
                      {/* <h4 style={{ fontSize: "24px" }}>{offre.category}</h4> */}
                      <Button
                        style={{
                          backgroundColor: "#f0cdc9",
                          marginTop: "15px",
                        }}
                        onClick={() =>
                          history.push(`/OffreDetails/${offre._id}`)
                        }
                      >
                        En savoir plus
                      </Button>
                    </div>
                    <div
                      style={{
                        bottom: 0,
                        textAlign: "left",
                        marginTop: "10px",
                        lineHeight: "30px",
                      }}
                    >
                      <div style={{ borderBottom: "inset" }}>
                        <WorkIcon />
                        {offre.category}
                      </div>
                      <div style={{ borderBottom: "inset" }}>
                        <RoomIcon />
                        {offre.location} {offre.gouvernorat}, {offre.country}
                      </div>

                      <div style={{ wordWrap: "break-word" }}>
                        {offre.description.length < 100
                          ? offre.description
                          : offre.description.slice(0, 100) + "..."}
                      </div>
                      <div style={{ float:"right" }}>
                        <AlarmIcon />
                        {data &&
                  offre.created_at &&
                  offre.created_at.slice(0, 10)}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            data && (
              <div className="no-offre">
                <p>
                  <i className="fas fa-exclamation"></i>
                  Pas d'offres disponibles pour le moment
                </p>
              </div>
            )
          )}
        </Container>
      </div>

      <Footer />
    </div>
  );
};

export default Offres;
