import { GET_OFFRES, GET_OFFRE } from "../actions/types";

const initialState = {
  offres: [],
  offre:{}
};
export default function offres(state = initialState, action) {
  switch (action.type) {
    case GET_OFFRES:
      return {
        ...state,
        offres: action.payload,
      };
      case GET_OFFRE:
      return {
        ...state,
        offre: action.payload,
      };
    default:
      return state;
  }
}
