import { GET_SUCCESS, EMAIL_SENT } from "../actions/types";
const initialState = {
  emailMsg: null,
};
export default function success(state = initialState, action) {
  switch (action.type) {
    case GET_SUCCESS:
      return action.payload;
    case EMAIL_SENT:
      return {
        ...state,
        emailMsg: action.payload,
      };
    default:
      return state;
  }
}
