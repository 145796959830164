import { parsePhoneNumber } from "react-phone-number-input";

export const validator = (values, fieldName) => {
  let errors = {};
  switch (fieldName) {
    case "email":
      validateEmail(values, errors);
      break;
    case "password":
      validatePassword(values, errors);
      break;
    case "phone":
      validatePhoneNumber(values, errors);
      break;
    default:
  }
  return errors;
};

// ******************************
function validateEmail(email, errors) {
  let result = true;
  if (!email) {
    errors.email = "L'e-mail est obligatoire";
    result = false;
  } else {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    result = re.test(String(email).toLowerCase());
    if (!result) errors.email = "Adresse e-mail invalide";
  }
  return result;
}
// ******************************
function validatePassword(pass, errors) {
  let result = true;
  if (!pass) {
    errors.password = "Mot de passe requis";
    result = false;
  } else {
    var lower = /(?=.*[a-z])/;
    result = lower.test(pass);

    if (!result) {
      errors.password =
        "Le mot de passe doit contenir au moins une lettre minuscule.";
      result = false;
    } else if (pass.length < 8) {
      errors.password = "Votre mot de passe comporte moins de 8 caractères.";
      result = false;
    }
  }

  return result;
}

function validatePhoneNumber(phone, errors) {
  let result = true;
  const phoneObject = parsePhoneNumber(phone);
  if (!phoneObject) {
    errors.phone = "Numéro de téléphone invalide";
    result = false;
  }
  return result;
}
