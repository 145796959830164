import React from "react";

import { Link, useHistory } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/footer.css";

const Footer = () => {
  const history = useHistory();
  return (
    // <div className="footer_area pt-150 pb-120 useBootstrap">
    //   <Container>
    //     <Row>
    //       <Col lg={4} md={6}>
    //         <div className="single_footer">
    //           <div>
    //             <img src={logo} style={{ width: "180px" }} />
    //           </div>
    //           <div className="single_footer_text">
    //             <p className="footer-para">
    //               La société MYSERV est une société de conseil et de services
    //               spécialisée totalement exportatrice dans le domaine de
    //               l’ingénierie informatique et technologies de l’information.
    //             </p>
    //           </div>
    //         </div>
    //       </Col>
    //       <Col lg={1} md={1}></Col>
    //       <Col lg={3} md={5}>
    //         <div className="single_footer">
    //           <div className="single_footer_title pb-4">
    //             <h4>A propos</h4>
    //           </div>
    //           <div className="single_footer_body">
    //             <ul>
    //               <li>
    //                 <a
    //                   onClick={() => history.push("/")}
    //                   className="single_footer_title pb-4"
    //                   style={{ cursor: "pointer" }}
    //                 >
    //                   Accueil
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   onClick={() => history.push("/offres")}
    //                   className="single_footer_title pb-4"
    //                   style={{ cursor: "pointer" }}
    //                 >
    //                   Nos offres
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   onClick={() => history.push("/evennements")}
    //                   className="single_footer_title pb-4"
    //                   style={{ cursor: "pointer" }}
    //                 >
    //                   Nos évènnements
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   onClick={() => history.push("/contact")}
    //                   className="single_footer_title pb-4"
    //                   style={{ cursor: "pointer" }}
    //                 >
    //                   Contact
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </Col>
    //       <Col lg={2} md={7}>
    //         <div className="single_footer">
    //           <div className="single_footer_title pb-4">
    //             <h4>Services</h4>
    //           </div>
    //           <div className="single_footer_body">
    //             <ul>
    //               <li>
    //                 <a
    //                   onClick={() => history.push("/services")}
    //                   className="single_footer_title pb-4"
    //                   style={{ cursor: "pointer" }}
    //                 >
    //                   Systeme D'information
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   onClick={() => history.push("/services")}
    //                   className="single_footer_title pb-4"
    //                   style={{ cursor: "pointer" }}
    //                 >
    //                   Réseaux & sécurité
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   onClick={() => history.push("/services")}
    //                   className="single_footer_title pb-4"
    //                   style={{ cursor: "pointer" }}
    //                 >
    //                   Développement informatique
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   onClick={() => history.push("/services")}
    //                   className="single_footer_title pb-4"
    //                   style={{ cursor: "pointer" }}
    //                 >
    //                   Gestion et mangement de projet
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   onClick={() => history.push("/services")}
    //                   className="single_footer_title pb-4"
    //                   style={{ cursor: "pointer" }}
    //                 >
    //                   Solution CLOUD
    //                 </a>
    //               </li>
    //               <li>
    //                 <a
    //                   onClick={() => history.push("/services")}
    //                   className="single_footer_title pb-4"
    //                   style={{ cursor: "pointer" }}
    //                 >
    //                   Data Center
    //                 </a>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </Col>
    //       <Col lg={2} md={5}>
    //         <div className="single_footer">
    //           <div className="single_footer_title pb-4">
    //             <h4>Social Media</h4>
    //           </div>
    //           <div className="single_footer_body">
    //             <ul>
    //               <li>
    //                 <a href="#">Facebook</a>
    //               </li>
    //               <li>
    //                 <a href="#">Twitter</a>
    //               </li>
    //               <li>
    //                 <a href="#">Linkedin</a>
    //               </li>
    //               <li>
    //                 <a href="#">Instagram</a>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </Col>
    //     </Row>
    //   </Container>
    //   {/* <!-- Footer Shape Image --> */}
    //   <div className="footer_shape">
    //     <div className="clouds">
    //       <img src={boy1} alt="01" className="cloud1" />
    //     </div>
    //   </div>
    //   <div className="footer_shape">
    //     <div className="clouds">
    //       <img src={boy2} alt="02" className="cloud2" />
    //     </div>
    //   </div>

    //   <div className="footer_shape">
    //     <div className="clouds">
    //       <img src={boy3} alt="03" className="cloud3" />
    //     </div>
    //   </div>
    // </div>
    <div className="footer">
      <div className="row">
        <div className="col-lg-3 col-12 logo-footer">
          <img
            src="/sources/logo1.webp"
            alt="logo"
            style={{ width: 180, height: 110 }}
          />
        </div>
        <div className="col-lg-3 col-12 footer-section">
          <div style={{ border: "none" }}>
            <Link to="/about">
              <h4>À propos</h4>
            </Link>
            <Link to="/">
              <p>Accueil</p>
            </Link>
            <Link to="/services/nosServices">
              <p>Services</p>
            </Link>

            <Link to="/offres">
              <p>Carrières</p>
            </Link>
            <Link to="/contact">
              <p>Contact</p>
            </Link>
            
          </div>
        </div>
        <div className="col-lg-3 col-12 footer-section">
          <h4>Contact</h4>
          <div>
            <span>
              <i className="fas fa-home"></i>
              11, rue Saad Zaghloul M’saken 4070, Tunisie
            </span>
            <br />

            <span>
              <i className="fas fa-phone"></i> +216 28 710 412
            </span>
            <br />
            <span>
              <i className="fas fa-envelope"></i> contact@myserv-consulting.com
            </span>
          </div>
        </div>
        <div className="col-lg-3 col-12 footer-social">
          <h4>SUIVEZ-NOUS</h4>
          <div style={{ paddingTop: 10 }}>
            <a
              href="https://www.facebook.com/MYSERV.SOUSSE"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/sources/fb.webp" alt="fb" />
            </a>
            <a
              href="https://www.instagram.com/myserv_sousse/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/sources/insta.webp" alt="instagram" />
            </a>

            <a
              href="https://www.linkedin.com/company/myserv-consulting/"
              target="_blank"
              rel="noreferrer"
            >
              <img src="/sources/linkedin.webp" alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
