import React, { useLayoutEffect } from "react";
import { Paper } from "@material-ui/core";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/dashboard.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import userProject from "../helpers/userList";
const ProjectsList = () => {
  const history = useHistory();
  const dataProjects = useSelector((state) => state.projectReducer.projects);
  console.log(dataProjects);
  let userProjects = userProject(
    dataProjects,
    jwt_decode(localStorage.token).userId
  );
  // userProjects = dataProjects.filter(
  //   (elm) => elm.employee === jwt_decode(localStorage.token).userId
  // );
  console.log(userProjects);
  console.log(dataProjects);
  console.log(dataProjects.map(({ employee }) => employee));
  // console.log(dataProjects[1].employee[2]);
  useLayoutEffect(() => {
    if (!localStorage.token) {
      history.push("/");
    }
  });
  return (
    <Paper>
      <NavBar />
      <div>
        <h2>Mes projets</h2>
        <Container className="map-tab">
          <Row style={{ justifyContent: "center" }}>
            {userProjects.map((el) => (
              <Col
                key={el._id}
                lg={4}
                md={6}
                sm={12}
                // onClick={() => history.push("/conge/create")}
                className="map-tab-div2"
                style={{ backgroundColor: "#fcddc3" }}
              >
                <h3 className="map-tab-title2" style={{ color: "#000" }}>
                  Titre:
                  <h4 className="conge-values">{el.title}</h4>
                </h3>
                <h3 className="map-tab-title2" style={{ color: "#000" }}>
                  Deadline:
                  <h4 className="conge-values">
                    {el.deadline.slice(0, 10)} à {el.deadline.slice(11, 16)}
                  </h4>
                </h3>
                <h3 className="map-tab-title2" style={{ color: "#000" }}>
                  Discription:<h4 className="conge-values">{el.discription}</h4>
                </h3>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <Footer />
    </Paper>
  );
};

export default ProjectsList;
