import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import LOGO from "../assets/logo.webp";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/navBar.css";
import { Helmet } from "react-helmet";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function NavBar() {
  const user = useSelector((state) => state.userReducer.user);
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();
  const history = useHistory();
  const [checked, setChecked] = React.useState(true);
  const [navPopShow, setNavPopShow] = React.useState(true);
  const [resize, setResize] = React.useState(false);
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 992) setResize(true);
      else setResize(false);
    });
  });
  useEffect(() => {
    if (window.innerWidth <= 992) setResize(true);
  }, []);
  const handleOpenNavPop = () => {
    setNavPopShow(!navPopShow);
  };

  // *************************Login Button

  return (
    <div
      className={classes.root}
      style={{
        position: "fixed",
        top: 0,
        zIndex: 10,
        width: "100%",
        background: "#fff",
      }}
    >
      <Helmet>
        <script
          src="https://kit.fontawesome.com/a076d05399.js"
          crossorigin
        ></script>
      </Helmet>
      <AppBar position="static" color="transparent">
        <Toolbar
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            height: "10vh",
            alignItems: "center",
          }}
        >
          <Col
            lg={2}
            color="inherit"
            style={{
              marginRight: "1rem",
              fontSize: "13.8pt",
              fontFamily: "Myriad Pro",
              cursor: "pointer",
            }}
            className="logo-myser"
            onClick={() => history.push("/")}
          >
            <a>
              <img src={LOGO} alt="MYSERV" style={{ height: "10vh" }} />
            </a>
          </Col>
          {/* <img src={LOGO} alt="MYSERV" href="/" style={{ height: "90px" }} /> */}
          {/* <Button color="inherit">LOGO</Button> */}
          <>
            {resize ? (
              <div style={{ margin: "0 auto" }}>
                {/* {loginButton} */}
                <Col
                  lg={7}
                  style={{
                    display: checked && "none",
                  }}
                  className="navWeb"
                  id="navWeb"
                >
                  <div id="navDiv" className="navDiv">
                    <a
                      id="navLink"
                      className="navLink"
                      onClick={() => history.push("/about")}
                    >
                      À propos
                    </a>
                  </div>
                  <div id="navDivServices" className="navDivServices navDiv">
                    <span className="navSpan">
                      <a
                        onClick={() => history.push("/services/nosServices")}
                        className="navLink"
                      >
                        Services
                      </a>
                    </span>
                  </div>
                  <div id="navDivCarière" className="navDivCarière navDiv">
                    <span className="navSpan">
                      <a
                        id="navLinkInSpan"
                        className="navLink"
                        onClick={() => history.push("/offres")}
                      >
                        Carrière
                      </a>
                    </span>
                    {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <Typography
                        paragraph
                        onClick={() => history.push("/offres")}
                        className="typo"
                      >
                        Offres d'emploi
                      </Typography>
                      <Typography
                        paragraph
                        onClick={() => history.push("/evennements")}
                        className="typo"
                      >
                        Événements
                      </Typography>
                    </Collapse> */}
                  </div>
                  <div
                    id="navDiv"
                    className="navDiv"
                    onClick={() => history.push("/contact")}
                  >
                    <a id="navLink" className="navLink">
                      <a id="navLink" className="navLink"></a>Contact
                    </a>
                  </div>
                  {/* {loginButton} */}
                </Col>
              </div>
            ) : (
              <Col lg={10} className="navWeb" id="navWeb">
                <div id="navDiv" className="navDiv">
                  <a
                    id="navLink"
                    className="navLink"
                    onClick={() => history.push("/about")}
                  >
                    À propos
                  </a>
                </div>
                <div id="navDivServices" className="navDivServices navDiv">
                  <span className="navSpan">
                    <a
                      onClick={() => history.push("/services/nosServices")}
                      className="navLink"
                    >
                      Services
                    </a>
                  </span>
                  {/* <div className="navListServices">
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    <li className="navLi">
                      <a href="#/projet" className="navA">
                        gestion et management de projet
                      </a>
                    </li>
                    <li className="navLi">
                      <a href="#/information" className="navA">
                        système d'information
                      </a>
                    </li>
                    <li className="navLi">
                      <a href="#/reseau" className="navA">
                        réseaux &amp; sécurité
                      </a>
                    </li>
                    <li className="navLi">
                      <a href="#/web" className="navA">
                        développement informatique
                      </a>
                    </li>
                    <li className="navLi">
                      <a href="#/cloud" className="navA">
                        solution cloud
                      </a>
                    </li>
                    <li className="navLi">
                      <a href="#/data" className="navA">
                        data center
                      </a>
                    </li>
                  </ul>
                </div> */}
                </div>
                <div id="navDivCarière" className="navDivCarière navDiv">
                  <span className="navSpan">
                    <a id="navLinkInSpan" className="navLink"
                    onClick={() => history.push("/offres")}>
                      Carrière
                    </a>
                  </span>
                  {/* <div className="navListCarière">
                    <ul
                      style={{
                        listStyleType: "none",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      <li className="navLi">
                        <a
                          onClick={() => history.push("/offres")}
                          className="navA"
                        >
                          Offres d'emploi
                        </a>
                      </li>
                      <li className="navLi">
                        <a
                          onClick={() => history.push("/evennements")}
                          className="navA"
                        >
                          Événements
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div
                  id="navDiv"
                  className="navDiv"
                  onClick={() => history.push("/contact")}
                >
                  <a id="navLink" className="navLink">
                    <a id="navLink" className="navLink"></a>Contact
                  </a>
                </div>

                {/* {loginButton} */}
              </Col>
            )}
          </>
          <input
            type="checkbox"
            id="check"
            className="check"
            defaultChecked={checked}
            onChange={() => setChecked(!checked)}
          />
          <label for="check" className="checkbtn">
            <i className="fas fa-bars"></i>
          </label>
        </Toolbar>
      </AppBar>
    </div>
  );
}
