import axios from "axios";
import { GET_ERRORS } from "../actions/types";
import { GET_EVENTS } from "../actions/types";

//import setAuthToken from "../token/authtoken";

//--------------Create Event -------------------------
export const createEvent = (eventData) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .post(`${process.env.REACT_APP_API_URL}/events/create`, eventData)
    .then((res) => {
      dispatch({
        type: GET_ERRORS,
        payload: { event: "Event Created succesfully" },
      });
      dispatch(getEventsList());
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//---------------Get Projects List-------------------

export const getEventsList = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/events`)
    .then((res) => {
      dispatch({
        type: GET_EVENTS,
        payload: res.data.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      // console.log(err);
    });
};

//--------------Edit Event----------------
export const editEvent = (eventData, id) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .patch(`${process.env.REACT_APP_API_URL}/events/${id}`, eventData)
    .then((res) => {
      dispatch(getEventsList());
      dispatch({
        type: GET_ERRORS,
        payload: { event: "Event Updated" },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

//---------------Delete Event--------------------
export const deleteEvent = (id) => (dispatch) => {
  // setAuthToken(localStorage.token);
  axios
    .delete(`${process.env.REACT_APP_API_URL}/events/${id}`)
    .then((res) => {
      dispatch(getEventsList());
      dispatch({
        type: GET_ERRORS,
        payload: { event: "Event Deleted" },
      });
      dispatch(getEventsList());
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const sendEvent = (data) => (dispatch) => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/events/candidature`, data)
    .then((res) => {
      dispatch({
        type: GET_ERRORS,
        payload: { offre: "Candudature send" },
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
