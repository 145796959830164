import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { GET_SUCCESS, GET_ERRORS } from "../actions/types";
import { sendOffre } from "../actions/offreAction";
import "../styles/applyDialog.css";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs({
  open,
  handleClose,
  title,
  sendTo,
}) {
  const dispatch = useDispatch();
  // const success = useSelector((state) => state.sucessReducer);
  // const error = useSelector((state) => state.errorReducer);
  const [offrePost, setOffrePost] = useState({
    nom: "",
    email: "",
    sujet: "",
    msg: "",
    cv: "",
    sendTo: "",
  });

  const onChange = (e) => {
    setOffrePost({ ...offrePost, [e.target.id]: e.target.value });
  };
  const onChangeCV = (e) => {
    setOffrePost({ ...offrePost, [e.target.id]: e.target.files[0] });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    offrePost.sujet= `Candidature pour l'offre : ${title}`
    formData.append("info", JSON.stringify(offrePost));
    formData.append("cv", offrePost.cv);

    dispatch(sendOffre(formData));
    // setTimeout(() => {
    //   dispatch({
    //     type: GET_SUCCESS,
    //     payload: {},
    //   });
    //   setOffrePost({
    //     nom: "",
    //     email: "",
    //     sujet: "",
    //     msg: "",
    //     cv: "",
    //     sendTo: "",
    //   });
    //   handleClose();
    // }, 2000);
  };

  useEffect(() => {
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
    dispatch({
      type: GET_SUCCESS,
      payload: {},
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, [title]);

  useEffect(() => {
    setOffrePost({
      ...offrePost,
      sendTo: sendTo,
    });
    // eslint-disable-next-line
  }, [sendTo]);

  return (
    <div>
      <Dialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Postuler
        </DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              display: "block",
              justifyContent: "center",
              flexDirection: "column",
              alignAtems: "center",
            }}
          >
            <h2
              style={{
                color: "#000",
                fontSize: "22px",
                fontWeight: "600",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              {title}
            </h2>
            <div
              style={{
                color: "#000",
                padding: "0",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <form
                style={{
                  width: "100%",
                  transition: ".5s",
                  padding: "3rem!important",
                }}
                onSubmit={onSubmit}
              >
                <label className="label-dialog">Nom*</label>
                <input
                  type="text"
                  id="nom"
                  required
                  onChange={onChange}
                  value={offrePost.nom}
                  className="input-dialog"
                />
                <label className="label-dialog">E-mail*</label>
                <input
                  type="email"
                  id="email"
                  required
                  value={offrePost.email}
                  onChange={onChange}
                  className="input-dialog"
                />

                <div style={{ marginBottom: "1rem" }}>
                  <label className="label-dialog">Message*</label>
                  <textarea
                    id="msg"
                    value={offrePost.msg}
                    required
                    onChange={onChange}
                    rows="5"
                    className="textarea-dialog"
                  ></textarea>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "start",
                    marginBottom: "10px",
                  }}
                >
                  <p>Selectionner votre CV* :</p>
                  <input
                    id="cv"
                    type="file"
                    required
                    onChange={onChangeCV}
                    accept=".pdf"
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "start",
                    color: "gray",
                    marginBottom: "10px",
                  }}
                >
                  *Champs requis
                </div>
                <button type="submit" className="button-dialog">
                  Envoyer
                </button>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
