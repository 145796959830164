import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Profile from "./Pages/Profile";
import Dashboard from "./Pages/Dashboard";
import CongeCreate from "./Pages/CongeCreate";
import CongeList from "./Pages/CongeList";
import ProjectsList from "./Pages/ProjectsList";
import EventDetails from "./Pages/EventDetails";
import OffreDetails from "./Pages/OffreDetails";
import Contact from "./Pages/Contact";
import Services from "./Pages/Services";
import Offres from "./Pages/Offres";
import Evennements from "./Pages/Evennements";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/services/:sectionId" component={Services} />
        <Route exact path="/offres" component={Offres} />
        <Route exact path="/evennements" component={Evennements} />
        <Route exact path="/contact" component={Contact} />
        {/* <Route exact path="/profile" component={Profile} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/conge/create" component={CongeCreate} />
        <Route exact path="/conges" component={CongeList} />
        <Route exact path="/projects" component={ProjectsList} /> */}
        <Route exact path="/EventDetails/:eventId" component={EventDetails} />
        <Route exact path="/OffreDetails/:offreId" component={OffreDetails} />
      </Switch>
    </div>
  );
}

export default App;
