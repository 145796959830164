import React from "react";
import { Paper } from "@material-ui/core";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { useHistory } from "react-router-dom";
import quisommesnous from "../assets/quisommesnous.webp";
import atous from "../assets/atous.webp";
import valeurs from "../assets/NosValeurs.webp";
import { Container } from "react-bootstrap";
import "../styles/about.css";

const About = () => {
  const history = useHistory();
  return (
    <Paper>
      <NavBar />
      <Container style={{ paddingTop: 70 }}>
        <div className="service-myserv">
          <div>
            <div>
              <img
                alt="quisommesnous"
                src={quisommesnous}
                style={{ height:"65%", width: "65%" }}
              />
            </div>
            <div>
              <h2 className="about-ct">
                Qui sommes-<span className="about-ctr">nous ?</span>
              </h2>
              <p className="about-cp">
                MYSERV est une société de conseil et de services dans le domaine
                de l’ingénierie informatique et technologies de l’information
                créée en 2019. Dans le cadre du conseil informatique
                l’entreprise se crée sur une simple idée est de proposer des
                solutions les plus performantes et innovantes du marché et de
                présenter des experts couvrants des technologies modernes pour
                fournir aux clients, une solution complète à la hauteur de leurs
                attentes.
              </p>
            </div>
          </div>

          <div>
            <div>
              <h2 className="about-ct">
                Nos<span className="about-ctr"> atouts</span>
              </h2>

              <div className="about-cp" style={{ textAlign: "initial" }}>
                <div className="icon">
                  <i
                    className="fa fa-thumbs-up"
                    style={{ color: "#4096e9" }}
                  ></i>{" "}
                  L’expertise technique et organisationnelle.
                </div>
                <div className="icon">
                  <i
                    className="fa fa-thumbs-up"
                    style={{ color: "#4096e9" }}
                  ></i>{" "}
                  La polyvalence des compétences.
                </div>
                <div className="icon">
                  <i
                    className="fa fa-thumbs-up"
                    style={{ color: "#4096e9" }}
                  ></i>{" "}
                  L’écoute et la culture du « Service Client ».
                </div>
              </div>
            </div>
            <div>
              <img src={atous} alt="atous" className="imgSize" />
            </div>
          </div>

          <div>
            <div>
              <img alt="quisommesnous" src={valeurs} className="imgSize" />
            </div>
            <div>
              <h2 className="about-ct">
                Nos <span className="about-ctr"> valeurs</span>
              </h2>
              <p className="about-cp">
                Notre image de société <strong>dynamique</strong> et{" "}
                <strong>performante</strong> est basée sur
                <strong> la réactivité</strong> et{" "}
                <strong>la flexibilité</strong> pour répondre aux besoins de nos
                clients. Avec ses valeurs,
                <strong>
                  l'organisation, la collaboration, la confiance , la
                  responsabilité
                </strong>
                , MYSERV est une équipe jeune et dynamique réunie pour répondre
                aux besoins de ses clients et les accompagner tout au long de
                leurs projets.
              </p>
            </div>
          </div>

          <button
            className="nousContact"
            onClick={() => history.push("/contact")}
          >
          <span>Contact <i aria-hidden="true" className="fas fa-long-arrow-alt-right"></i></span>   
          </button>
        </div>
      </Container>

      <Footer />
    </Paper>
  );
};

export default About;
